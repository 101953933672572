<template>
  <div class="card p-0 card-wallet-hover">
    <div class="card-body mb-0 p-3">
      <div class="row">
        <div class="col-6" v-loading="loading">
          <span v-if="balance">{{balance.balance}}</span>
        </div>
        <div class="col-6 text-right">
          <span class="mr-1">{{ data.currency }}</span>
          <el-image
            style="width: 40px; height: auto; vertical-align: middle"
            :src="`/img/currency-flags/${data.currency.toLowerCase()}.png`"
            fit="contain"
          ></el-image>
        </div>
      </div>
      <div v-if="upgradePlus" class="row">
        <div class="col-12 text-right">
          <span style="font-size: 10px"
            >Wallet ID {{ data.bankholderID }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BankApi from "@/api/bankaccount";
export default {
  props: {
    data: {
      type: Object
    }
  },
  computed:{
    accessRole() {
      return this.$store.getters.accessrole;
    },
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
  },
  data(){
    return{
      loading: false,
      balance: null
    }
  },
  methods:{
    getBalance(){
      this.loading = true
      BankApi.getAccountBalance(this.data.bankholderID).then(({data})=>{
        this.loading = false
        this.balance = data
      })
    },
  },
  mounted(){
   if(this.accessRole.balance) this.getBalance()
  }
};
</script>

<style>
   
</style>