<template>
  <el-dropdown 
  @command="onSelected"
  >
    <div style="background: #ebeef5; padding: 15px; border-radius: 15px">
      <el-image
      v-if="currencySelected"
        style="width: 48px; height: 32px; vertical-align: middle"
        :src="`/img/currency-flags/${currencySelected.toLowerCase()}.png`"
        fit="contain"
      >
      </el-image>
      <span class="el-dropdown-link">
        <span style="margin-left: 10px; font-size: 1.2rem">{{
          currencySelected ? currencySelected.toUpperCase() : (currencies.length > 0 ? "Select Currency" : "No item")
        }}</span
        ><i class="el-icon-arrow-down el-icon--right" v-if="currencies.length > 0"></i>
      </span>
    </div>

    <el-dropdown-menu 
    slot="dropdown"
    v-if="currencies.length > 0"
    >
      <el-dropdown-item
        v-for="currency of currencies"
        :key="currency.ledger_id"
        :command="currency.currency"
      >
        <el-image
         
          style="width: 48px; height: 32px; vertical-align: middle"
          :src="`/img/currency-flags/${currency.currency.toLowerCase()}.png`"
          fit="contain"
        >
       
        </el-image>
        <span
      
          style="margin-left: 10px; font-size: 1.2rem"
          >{{ currency.currency.toUpperCase() }}</span
        >
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "DropdownWalletCreate",
  props: {
    currencies: {
      type: Array,
      default: () => [],
    },

    
  },

  data(){
    return {
      currencySelected : "",
    }
  },

  methods: {
    onSelected(currency) {
      this.currencySelected = currency;
      this.$emit('onChange', currency);
    },
  },
};
</script>

<style>
</style>