<template>
  <div class="wallet-dashboard">
    <div class="container-fluid mt--5">
      <account-manage :create="false" />
      <favorite-manage v-if="accessRole.payment" :showall="true" :limit="6" />
      <history-manage v-if="accessRole.payment" :limit="15" :show-control="false"/>
      <renew-passport/>
    </div>
      
  </div>
</template>

<script>
import AccountManage from "@/views/Wallet/Account/components/AccountManage.vue";
import FavoriteManage from "@/views/Wallet/Favorite/components/FavoriteManage.vue";
import HistoryManage from '@/views/Wallet/History/components/HistoryManage.vue'
import RenewPassport from '@/components/RenewPassport.vue'

export default {
  components: {
    AccountManage,
    FavoriteManage,
    HistoryManage,
    RenewPassport
  },
  computed:{
    accessRole() {
      return this.$store.getters.accessrole;
    },
  },
  data(){
    return {
    }
  },
  methods:{
   
  },
  mounted() {},
};
</script>

<style>
</style>