<template>
  <div class="wallet-account-manage">
    <div class="card mb-3">
      <div class="card-header">
        <h3 class="mb-0"><i class="el-icon-s-finance"></i> My Account</h3>
        <div v-if="create" class="position-absolute top-2 right-2 pt-1 pr-1">
          <el-button
            v-if="kycApprove"
            type="success"
            class="btn btn-success btn-block"
            icon="el-icon-plus"
            :loading="loadingGetWallet"
            @click="getWalletCreate"
          >
            Add Account
          </el-button>
        </div>
      </div>
      <div class="card-body min-vh" v-loading="loading">
        <div class="row">
          <div
            v-for="wallet of walletLists"
            :key="wallet.ledger_id"
            @click="gotoWalletAccountCurrency(wallet)"
            :class="`col-md-6 col-lg-4 col-xl-3 col-xxl-2 mb-3 currency-color-${wallet.currency.toLowerCase()}`"
          >
            <wallet-card :data="wallet" />
          </div>

         
        </div>
         <div v-if="kycApprove && walletLists.length == 0" class="text-center">
            <h1>You KYC now approve</h1>
            <h2>Let create wallet and spend!</h2>
            <el-button type="primary" @click="getWalletCreate">Create wallet</el-button>
          </div>
          <div v-if="!kycApprove" class="text-center">
            <h1>You KYC {{ kycStatus }}</h1>
            <h2>Please finish your KYC on AB app</h2>
          </div>
      </div>
    </div>

    <el-dialog
      title="Select Wallet Currency for Create"
      :visible.sync="dialogCreateWallet"
      width="350px"
    >
      <div class="align-items-center">
        <div class="row justify-content-md-center">
          <dropdown-wallet-create
            style="margin: auto"
            :currencies="walletCreateLists"
            @onChange="onSelected"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCreateWallet = false">Cancel</el-button>
        <el-button type="primary" @click="onCreateWallet()" :loading="submiting"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import WalletApi from "@/api/wallet/wallet";
import WalletCard from "./WalletCard.vue";
import DropdownWalletCreate from "./DropdownWalletCreate.vue";
import AccountApi from "@/api/wallet/account"
export default {
  props: {
    create: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    WalletCard,
    DropdownWalletCreate,
  },
  computed: {
    kycApprove() { 
      return this.kycStatus && this.kycStatus == 'approve'
    }
  },
  data() {
    return {
      loading: false,
      loadingGetWallet: false,
      dialogCreateWallet: false,
      submiting: false,
      walletLists: [],
      walletCreateLists: [],
      currencySelected: "",
      kycStatus: "",
    };
  },
  methods: {
    getKyc() {
      AccountApi.getKycState().then(({ result, data }) => {
        if (result) {
          this.kycStatus = data['status'];
        }
      })
    },
    getWallet() {
      this.loading = true;
      WalletApi.getWalletList().then(({ result, data }) => {
        this.loading = false;
        if (result) {
          this.walletLists = data;
        }
      });
    },
    getWalletCreate() {
      this.walletCreateLists = [];
      this.loadingGetWallet = true;
      WalletApi.getCurrencyActiveCreate().then(({ result, data }) => {
        this.loadingGetWallet = false;
        if (result) {
          this.walletCreateLists = data;
          this.dialogCreateWallet = true;
        }
      });
    },
    onSelected(currency) {
      this.currencySelected = currency;
    },

    onCreateWallet() {
      if (!this.currencySelected) {
        this.$swal("Message", "Please select currency", "error");
        return false;
      }

      this.submiting = true;
      WalletApi.createWallet({ currency: this.currencySelected }).then(
        ({ result, message }) => {
          this.submiting = false;
          if (result) {
            this.dialogCreateWallet = false;
            this.currencySelected = "";
            this.getWallet();
          } else {
            this.$swal("Message", message, "error");
          }
        }
      );
    },
    gotoWalletAccountCurrency(wallet) {
      this.$router.push({
        path: `/wallet/account/${wallet.currency}/${wallet.bankholderID}`,
      });
    },
  },
  mounted() {
    this.getKyc();
    this.getWallet();
  },
};
</script>

<style lang="scss">
.wallet-account-manage {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

}
</style>